<template>
  <div class="Sales">
    <div style="padding-bottom: 100px;
          height: 100%;
          padding-top: 10px;
          padding-left: 10px;
          padding-right: 10px;
        ">
      <div class="top" style="">
        <div class="flex">
          <el-select v-model="selectKey" style="width: 130px" @change="selectChange">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
            </el-option>
          </el-select>
          <el-input :placeholder="placeholder" v-model="queryParam.Keywords" @input="inputChange"
            @keydown.enter.native="search"></el-input>
        </div>
        <div class="flex" style="margin-top: 10px">
          <el-date-picker v-model="queryParam.StartDate" type="date" placeholder="开始日期" @change="startTimeChange"
            value-format="yyyy-MM-dd">
          </el-date-picker>
          <el-date-picker v-model="queryParam.EndDate" type="date" placeholder="结束日期" @change="endTimeChange"
            value-format="yyyy-MM-dd">
          </el-date-picker>
        </div>
      </div>
      <div style="margin-top: 10px">

        <!-- :summary-method="getSummaries"
          show-summary:max-height="tableHeight" -->
        <el-table ref="queryForm" :height="tableHeight" element-loading-text="拼命加载中" v-loading="loading"
          v-el-table-infinite-scroll="scrollLoad" :data="tableData" id="tables" :infinite-scroll-immediate="false"
          :infinite-scroll-disabled="scrollDisaled">
          <!-- 客户列 -->
          <el-table-column align="left" prop="Name" :label="label" ></el-table-column>
          <!-- 销售金额列 -->
          <el-table-column align="left" prop="" label="销售金额" width="100">
            <el-table-column v-if="queryParam.Type==1"  align="left" prop="TotalNum" label="数量" width="100">
            </el-table-column>
            <el-table-column align="left" prop="ReceiveSum" label="现结" width="100">
            </el-table-column>
            <el-table-column align="left" prop="PendingSum" label="挂账" width="100">
            </el-table-column>
            <el-table-column align="left" prop="TotalSum" label="合计" width="100">
            </el-table-column>
          </el-table-column>
        </el-table>
        <!-- <div
          v-if="tableData.length == pagination.total"
          style="text-align: center; padding-top: 10px"
        >
          没有更多了
        </div> -->
      </div>
    </div>

    <!-- 下拉客户显示的底部 -->
    <div v-if="queryParam.Type == 0 || queryParam.Type == 2" style=" padding: 10px 10px; position: fixed;
          bottom: 0;
          background-color: #fff;
          width: 100%;
        ">
      <div style="">
        <table border style="border: 1px solid #ebeef5">
          <tr style="">
            <td style="
                  width: 230px;
                  border: 1px solid #ebeef5;
                  text-align: center;
                  padding: 10px 0;
                ">
              合计
            </td>
            <td style="
                  width: 120px;
                  border: 1px solid #ebeef5;
                  text-align: center;
                  padding: 10px 0;
                  font-size:13px;
                  dispaly:flex; 
                  flex-direction: column;
                "> 
                  <div>现结</div>
                  <div>{{ toThousands(total.totalReceiveSum) }}</div>
            </td>
            <td style="
                  width: 120px;
                  border: 1px solid #ebeef5;
                  text-align: center;
                  padding: 10px 0;
                  font-size:13px;
                  dispaly:flex; 
                  flex-direction: column;
                ">
               <div>挂账</div>
               <div> {{ toThousands(total.totalPendingSum) }}</div>
            </td>
            <td style="
                  width: 120px;
                  border: 1px solid #ebeef5;
                  text-align: center;
                  padding: 10px 0;
                  font-size:13px;
                  dispaly:flex; 
                  flex-direction: column;
                ">
                <div> 总合计</div>
                <div>{{ toThousands(total.totalSum) }}</div>
            </td>
          </tr>
        </table>
      </div>
      <!-- <el-pagination
      style=" "
      layout="total,prev, pager, next,sizes"
      :total="pagination.total"
      :current-page="pagination.current"
      :page-size="pagination.pageSize"
      :page-sizes="pagination.pageSizeOptions"
      @current-change="currentChange"
      @size-change="handleSizeChange"
    >
    </el-pagination> -->
    </div>


    <!-- 下拉货品显示的底部 -->
    <div v-if="queryParam.Type == 1" style=" padding: 20px 10px;
      position: fixed;
      bottom: 0;
      background-color: #fff;
      width: 100%;">
      <div style="display:flex;">
        <!--  -->
        <div style="flex:1;dispaly:flex;align-items:center;padding-top:10px">
          合计:
        </div>
        <!-- justify-content: center; -->
        <div style="display:flex; flex:9;justify-content: space-around;width:100%;">
          <div style="display:flex;flex-direction: column;align-items:center">
            <div>数量</div>
            <div style="font-size:13px">{{ total.totalNum }}</div>
          </div>
          <div style="display:flex;flex-direction: column;align-items:center">
            <div>现结</div>
            <div style="font-size:13px">{{ toThousands(total.totalReceiveSum) }}</div>
          </div>
          <div style="display:flex;flex-direction: column;align-items:center">
            <div>挂账</div>
            <div style="font-size:13px">{{ toThousands(total.totalPendingSum) }}</div>
          </div>
          <div style="display:flex;flex-direction: column;align-items:center">
            <div>总合计</div>
            <div style="font-size:13px">{{ toThousands(total.totalSum) }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toThousands, getMonthRange } from "../../../utils/Tools";
export default {
  data() {
    return {
      // 表格头部
      label: "客户",
      scrollDisaled: false,
      tableHeight: 200,
      getMonthRange,
      // 千分位格式化
      toThousands,
      // 下拉选择数据
      options: [
        {
          value: "0",
          label: "客户",
        },
        {
          value: "1",
          label: "货品",
        },
        {
          value: "2",
          label: "业务员",
        },
      ],
      // 表格属性数据
      tablePropListData: [
        {
          label: "客户",
          prop: "Name",
        },
      ],
      tablePropListData1: {
        top: [
          {
            label: "销售金额",
            prop: "TotalSum",
          },
        ],
        bottom: [
          {
            label: "现结",
            prop: "ReceiveSum",
          },
          {
            label: "挂账",
            prop: "PendingSum",
          },
          {
            label: "合计",
            prop: "TotalSum",
          },
        ],
      },

      loading: false,
      // 开始日期
      StartTime: "",
      // 结束日期
      EndTime: "",
      // 收款统计列表数据
      tableData: [],
      selectKey: "客户",
      placeholder: "请输入客户名称进行查询",
      searchKey: "",
      date: [],
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        pageSizeOptions: [10, 30, 50],
      },
      sorter: {
        field: "CreateTime",
        order: "desc",
      },
      queryParam: {
        CompanyId: "",
        StartDate: "",
        EndDate: "",
        Keywords: "",
        Type: 0,
      },
      total: {
        // 挂账总金额
        totalPendingSum: 0,
        // 现结总金额
        totalReceiveSum: 0,
        // 总合计金额
        totalSum: 0,
        // 货品总数量  在选择货品下拉时显示
        totalNum: 0
      },
      // 现结总金额
    };
  },
  mounted() {
    this.$nextTick(() => {
      // window.innerHeight 浏览器窗口的可见高度，下面的 220 是除了table最大高度的剩余空间。
      let height =
        document.documentElement.clientHeight - this.$refs.queryForm.$el.offsetHeight - 30;
      this.tableHeight = height;
    });


  },
  created() {
    document.title = "销售统计"



    if (this.$route.query.token) {
      sessionStorage.setItem("token", this.$route.query.token);
    }

    this.queryParam.CompanyId = this.$route.query.CompanyId;
    this.pagination.token = this.$route.query.token;

    let range = getMonthRange(1);
    this.queryParam.StartDate = range[0];
    this.queryParam.EndDate = range[1];
    // console.log(
    //   "获取路由获取路由获取路由获取路由",
    //   this.$route.query,
    //   this.pagination
    // );

    this.toGetList();

  },
  methods: {
    toGetList() {
      this.pagination.current = 1;
      this.scrollDisaled = true;
      this.tableData = [];
      // 获取销售统计列表数据
      this.getCollectionStatistics()
      // 获取销售金额统计数据
      this.getGetLinqBillingStatistics()

    },
    // 表格滚动加载
    scrollLoad() {
      // 监听表格滚动事件
      this.pagination.current++;
      if (this.pagination.current > 1 && this.tableData.length == this.pagination.total) {
        return
      }
      // 获取销售统计列表数据
      this.getCollectionStatistics()
    },
    returnFloat(value) {
      var value = Math.round(parseFloat(value) * 100) / 100;

      var xsd = value.toString().split(".");

      if (xsd.length == 1) {
        value = value.toString() + ".00";

        return value;
      }

      if (xsd.length > 1) {
        if (xsd[1].length < 2) {
          value = value.toString() + "0";
        }

        return value;
      }
    },
    // 合计
    getSummaries(param) {
      // const { columns, data } = param;
      // const sums = [];
      // columns.forEach((column, index) => {
      // 	if (index === 0) {
      // 		sums[index] = '合计';
      // 		return;
      // 	}else{
      //页面分别统计 处理
      //     const values = data.map(item => Number(item[column.property]))
      //     if (!values.every(value => isNaN(value))) {
      //       sums[index] = values.reduce((prev, curr) => {
      //         const value = Number(curr)
      //         if (!isNaN(value)) {
      //           return prev + curr
      //         } else {
      //           return prev
      //         }
      //       }, 0)
      //       console.log('获取综合',sums)
      //     }
      //   }
      // });
      // console.log('打印计算后的列表', sums)
      // return sums;
    },

    // 时间选择
    startTimeChange(e) {
      console.log("开始时间", e);
      this.queryParam.StartDate = e;
      // 同步获取销售列表统计数据和总金额统计数据
      this.toGetList()
    },
    endTimeChange(e) {
      console.log("结束时间", e);
      this.queryParam.EndDate = e;
      // 同步获取销售列表统计数据和总金额统计数据
      this.toGetList()
    },
    // 搜索输入框输入
    inputChange(e) {
      console.log("搜索框输入", e);
      this.queryParam.Keywords = e;
    },
    // 分页事件
    currentChange(page) {
      this.pagination.current = page;
      this.getCollectionStatistics();
    },
    handleSizeChange(lint) {
      this.pagination.pageSize = lint;
      this.getCollectionStatistics();
    },
    // 搜索
    search() {
      // 同步获取销售列表统计数据和总金额统计数据
      this.toGetList()
    },
    // 获取销售金额总计数据
    getGetLinqBillingStatistics() {
      this.$http.post("/BO/BIlling/GetLinqBillingAllStatistics", {
        PageIndex: this.pagination.current,
        PageRows: this.pagination.pageSize,
        Search: this.queryParam
      }).then((resJson) => {
        if (resJson.Success) {
          console.log('获取销售金额金额金额额金额金额统计数据额金额金额统计数据额金额金额统计数据统计数据', resJson.Data)
          if (resJson.Data == null) {
            this.total.totalPendingSum = 0
            this.total.totalReceiveSum = 0
            this.total.totalSum = 0
            this.total.totalNum = 0
            return
          }
          this.loading = false
          //  总挂账金额赋值
          this.total.totalPendingSum = resJson.Data.PendingSum
          //  总现收金额赋值
          this.total.totalReceiveSum = resJson.Data.ReceiveSum
          //  总合计金额赋值
          this.total.totalSum = resJson.Data.AmountTo
          //  总商品数量赋值
          this.total.totalNum = resJson.Data.TotalNum



        } else {
          this.loading = false
        }
      });
    },

    // 获取销售统计列表数据
    getCollectionStatistics() {
      this.loading = true;
      this.$http
        .post("/BO/BIlling/GetLinqBillingStatistics", {
          PageIndex: this.pagination.current,
          PageRows: this.pagination.pageSize,
          Search: this.queryParam,
        })
        .then((resJson) => {
          if (resJson.Success) {

            this.loading = false;

            // 没数据的时候，禁用 infinite-scroll-disabled 滚动事件
            if (resJson.Data.length == 0) {
              this.scrollDisaled = true;
              return
            }

            this.scrollDisaled = false;

            if (resJson.Data) {
              const pagination = { ...this.pagination };
              pagination.total = resJson.Total;
              this.pagination = pagination;
            }
            resJson.Data.forEach((item) => {
              // 所有金额格式化
              item.ReceiveSum = toThousands(item.ReceiveSum);
              item.PendingSum = toThousands(item.PendingSum);
              item.TotalSum = toThousands(item.TotalSum);
              if (this.queryParam.Type == 1) {
                item.productInv =
                  (item.Name == null ? "" : item.Name) +
                  (item.GroupName == null ? "" : "/" + item.GroupName) +
                  (item.cInvStd == null ? "" : "/" + item.cInvStd);
              }
            });

            this.tableData = this.tableData.concat(resJson.Data)

            if (this.pagination.current * this.pagination.pageSize >= this.pagination.total) {
              return
            }


          } else {
            this.loading = false;
          }
        });
    },
    // 下拉选择事件
    selectChange(e) {
      this.queryParam.Type = e;
      console.log('this.queryParam.Type', e, this.queryParam.Type)
      this.loading = false;
      this.tableData = [];
      this.queryParam.Keywords = "";
      this.pagination.current = 1;
      let arr0 = [
        {
          label: "客户",
          prop: "Name",
        },
      ];
      let arr1 = [
        {
          label: "货品",
          prop: "productInv",
        },
        {
          label: "数量",
          prop: "TotalNum",
        },
      ];
      let arr2 = [
        {
          label: "人员",
          prop: "Name",
        },
      ];
      console.log(e);

      if (e == 0) {
        this.tablePropListData = arr0;
        this.placeholder = "请输入客户名称进行查询";
        this.label='客户'
      } else if (e == 1) {
        this.tablePropListData = arr1;
        this.placeholder = "请输入货品名称进行查询";
        this.label='货品'
      } else {
        this.tablePropListData = arr2;
        this.placeholder = "请输入业务员名称进行查询";
        this.label='业务员'
      }
      // 同步获取销售列表统计数据和总金额统计数据
      this.toGetList()
      // this.placeholder = "请输入" + e + "名称进行查询";
      // this.searchKey = "";
    },
  },
};
</script>

<style lang="less" scoped>
.flex {
  display: flex;
}

.Sales {
  width: 100%;
  height: 100%;
  background-image: url("../../../assets/App/background.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding-top: 5px;

  .top {
    padding: 10px;
    background: rgba(255, 255, 255, 0.6);
    border-radius: 5px;
  }
}
</style>
